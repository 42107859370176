import React from 'react'
import {useState} from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.jpg'
import {navItems} from '../../data'
import Dropdown from '../Dropdown'
import {FaBars} from 'react-icons/fa'
import {MdOutlineClose} from 'react-icons/md'
import './styles.css'




const Navbar = () => {
  const [isNavShowing, setIsNavShowing] = useState(false);

  return (
    <div className='dashbd'>
      <div className='container nav-bar'>
        <Link to="/" className='logo'>
          <img src={Logo} alt="Nav Logo"/>
        </Link>
        <ul className= {`navList ${isNavShowing ? 'show__nav' : 'hide__Nav'}`}>
          {
            navItems.map((item) => {
              if (item.title === "Services") {
                return (
                  <li>
                    <Link><span>{item.title}</span></Link>
                    <Dropdown/>
                  </li>
                )
              }

              return (
                <li key={item.id}>
                    <Link to={item.path}><span>{item.title}</span></Link>
                </li>
            )
            })
          }
        </ul>

        <button className="nav__toggle-btn" onClick={() => setIsNavShowing (prev => !prev)}>
                {
                    isNavShowing ? <MdOutlineClose/> : <FaBars/>
                }

          <div className="menu">
            <h4>Menu</h4>
          </div>
        </button>
      </div>
    </div>
  )
}

export default Navbar