import React from 'react'
import {Link} from 'react-router-dom'
import Header from "../../components/Header"
import serviceImg from '../../images/serviceImg.jpg'
import VisitCare from '../../images/VisitCare.jpg'
import LiveIn from '../../images/LiveIn.jpg'
import Meta from '../../components/Meta'

import './services.css'


const Services = () => {
  return (
    <>
    <Meta title={"Services"} />
    <Header className="servicesImg" title="SERVICES" image={serviceImg}>
    </Header>

        <section className="services">
          <div className="container services-cointainer">
            <div className="services_section-image">
                <img src={VisitCare} alt="visitingCare-pic" />
            </div>
            <div className="services_section-content">
                <h3>Visiting Care</h3>
                <p>
                    Details coming soon................
                </p>
                <div className="visitCare">
                    <h5>Personal Care</h5>
                    <p>Details coming soon.....</p>
                    <h5>Domestic Assistance</h5>
                    <p>Details coming soon.....</p>
                    <h5>Nutrition & Hydration</h5>
                    <p>Details coming soon.....</p>
                    <h5>Getting Home from Hospital</h5>
                    <p>Details coming soon.....</p>
                    <h5>Medication Assistance</h5>
                    <p>Details coming soon.....</p>
                    <h5>Overnight Care</h5>
                    <p>Details coming soon.....</p>
                    <h5>Respite Care</h5>
                    <p>Details coming soon.....</p>
                    <h5>Companionship & Outings</h5>
                    <p>Details coming soon.....</p>
                </div>
                <div className="bttn__card">
                    <button>
                    <Link to="/o-hub" className='lnk'> MORE INFO </Link>
                    </button>
                </div>
            </div>
          </div>

        </section>

        <section className="services-liveIn">
          <div className="container services-cointainer">
            <div className="servicesLiveIn_section-image">
                <img src={LiveIn} alt="liveIn-pic" />
            </div>
            <div className="servicesLiveIn_section-content">
                <h3>Live-in Care</h3>
                <p>
                  Details coming soon................
                </p>
                <div className="LiveInCare">
                    <h5>Personal Support</h5>
                    <p>Details coming soon.....</p>
                    <h5>Medication Assistance</h5>
                    <p>Details coming soon.....</p>
                    <h5>Companionship</h5>
                    <p>Details coming soon.....</p>
                    <h5>Housekeeping</h5>
                    <p>Details coming soon.....</p>
                    <h5>Shopping</h5>
                    <p>Details coming soon.....</p>
                    <h5>Social Assistance</h5>
                    <p>Details coming soon.....</p>
                </div>
                <div className="bttn__card">
                    <button>
                    <Link to="/o-hub" className='lnk'> MORE INFO </Link>
                    </button>
                </div>
            </div>
          </div>

        </section>
    </>
  )
}

export default Services