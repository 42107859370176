import Header from "../../components/Header"
import HeaderImage from '../../images/blogH.JPG'
import BlogImage from '../../images/Blog1.jpg'

import "./blogPages.css"
import {Link} from 'react-router-dom'



const Blog1 = () => {
  return (
    <>
    <Header title="WHAT’S TRENDING..., NEWS, UPDATES, JOBS!" image={HeaderImage}>
    </Header>

    <section className="blog__vision">
        <div className="blog__section-image">
            <img src={BlogImage} alt="" />
        </div>
      <div className="container blog-container">
        <div className="blog__section-content">
        <h2>10 ways agency workers can prepare for revalidation </h2>
            <p>
                Revalidation is a process that all nurses and midwives need to go through, to complete their registration with the NMC.
            </p>
            <p>
                It's natural for any nurse or midwife to feel nervous about revalidation, but for agency staff who don't regularly work in a large NHS Trust or who work in an independent provider of care organisation, this pressure can feel even greater. However, revalidation is no harder for agency nurses to complete although there are some simple things to bear in mind: 
            </p>
            <h4> 1. Sign up to NMC Online and check your revalidation date </h4>
            <p>
                Use NMC Online to manage your registration. Set up a free account and check your renewal date. 
            </p>
            <p>
                The NMC will notify you at least 60 days before your application for revalidation is due. You then have 60 days to log in to NMC Online and complete the application process.
            </p>

            <h4>2. Give yourself time to learn the process </h4>
            <p>
                Remember, you will be revalidating on your practice for the whole three-year period up to this date, so it is important that you understand what is being asked of you.
            </p>
            <p>
                Start collecting your evidence, build up your portfolio and discuss your revalidation evidence with others - that way you'll avoid any unnecessary surprises when you receive your 60-day reminder from the NMC.
            </p>
            
            <h4>3. Learn from others </h4>
            <p>
                To demonstrate to your confirmer that you have met the minimum number of practice hours required to revalidate, it is important to keep a record of your work. The NMC have produced a template for this.
            </p>
            <p>
                Practice hours include time spent providing direct care to patients, managing teams, teaching others and helping to shape policy or run a care service - any time in which you have used your knowledge and experience as a nurse. It does not just mean hands on clinical practice.
            </p>

            <h4>5. Don't be afraid to ask for feedback </h4>
            <p>
                Getting feedback can be an uncomfortable process but it's vital to help you reflect upon and improve your practice. Questionnaires and simple feedback forms (including this version from the NMC) are a useful way to find out what you are doing well and what you could be doing better. Feedback can be from any point over the three years up to your revalidation date, so there are plenty of opportunities to ask.
            </p>

            <h4>6. Think about your CPD </h4>
            <p>
                As part of your revalidation, you must undertake 35 hours of continuing professional development, 20 of which must be in participation with other learners. Treatments and technologies are always changing so it's important to stay one step ahead.
            </p>
            <p>
                There are many free and discounted courses throughout the year and opportunities to learn online, so start early to make the most of the free courses
            </p>

            <h4>7. Regularly update your portfolio </h4>
            <p>
                You may already have a portfolio with information about the training or courses you have completed in the last three years. If not - start collecting and storing your evidence now and make sure it’s saved in a safe place.
            </p>
            <p>
                Your portfolio can be paper based, stored on a computer or online in a digital format. 
            </p>

            <h4>8. Identify your reflective discussion partner and your confirmer </h4>
            <p>
                As a nurse or midwife, it's your responsibility to find an appropriate person who you can have your reflective discussion with; someone who can review your portfolio and confirm to the NMC that you have completed all the revalidation requirements.
            </p>
            <p>
                If you work with a more senior NMC registrant, or your line manager is an NMC registrant, then they are a sensible person to ask.
            </p>
            <p>
                For agency nurses who feel isolated and unable to identify an appropriate person, connections can be made through professional networks. For example, RCN members can connect via RCN Forums.
            </p>

            <h4>9. Seek out support </h4>
            <p>
                There is a wealth of support available for agency staff, including opportunities to attend free courses on revalidation across the UK.
            </p>
            <p>
                Professional organizations such as the RCN and the NMC have information to help you too.
            </p>

            <h4>10. Speak to colleagues </h4>
            <p>
                It’s important to remember that even as an agency nurse you are part of the nursing team, and the relationships you build with colleagues will enable you to approach revalidation with confidence.
            </p>
            <p>
                This guidance was produced by the RCN in association with Hallam Medical.
            </p>
        </div>

          <Link to="/candidate" className='lnk'>CONTACT US </Link>

    </div>

    </section>

    </>
  )
}

export default Blog1