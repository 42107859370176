import React from 'react'
import Header from '../../components/Header'
import HeaderImage from '../../images/policy.jpg'
import Meta from '../../components/Meta'
import MyTable from '../../UI/MyTable'
import "./policy.css"

const Policy = () => {
  return (
    <>
    <Meta title={"Policy"} />
    <Header title="PRIVACY POLICY, TERMS & CONDITIONS" image={HeaderImage}>
    </Header>



    <section className="terms">
    <div className="condition">
      <h2>Terms & Conditions</h2>
      <p>
        This privacy policy tells you what will happen to any personal data that you provide to Odins Medical as a result of using this website or contacting this organization. We fully understand that your privacy is important to you and that you care about how your personal data is used and shared online and we will take account of, and respect, your concerns. 
      </p>
      <p>
        This policy explains how we will use, and protect, the information that we gather, whether it be through this website, by way of telephone or personal conversations or through our normal business contacts with you. Please read this privacy policy carefully and ensure that you understand it. Details are given below of contacts should you wish to ask questions but please note that acceptance of this privacy policy and our cookie policy (see “Cookies” below) is required to make full use of our site. 
      </p>
    </div>


      <div className='details'>
      <h4>
        Our details 
      </h4>
      <MyTable />
      <br/>

        <div>
        <p>
          We are registered with the Information Commissioner’s Office (ICO).
        </p>
        <p>
          Our Data Protection Officer (DPO) Somuadina Reuben Odinnuta  can be contacted at: Reuben@odinsmedical.com. 
        </p>
        </div>
      </div>



  </section>

  <section className='terms'>
    <div className='your-right'>
          <h4>Your rights </h4>
          <span>
            Under the General Data Protection Regulation (GDPR), you have the right to be informed about: 
          </span>
          <nav className="benefits">
            <ul>
              <li> the collection and use of your personal data </li>
              <li> our purposes for processing that data. </li>
              <li>the retention periods for storing your data (or a guarantee that it will be kept only for as long as necessary)  </li>
              <li>who it will be shared with (both in this country and, if applicable, in others: in this case, we will inform you of the safeguards which are applied in that country)</li>
              <li>the legal basis under which we process your data</li>
              <li>the right to withdraw your consent (if consent is the legal basis for processing)</li>
              <li>our “legitimate interest” in processing your data (if that interest is the legal basis for processing)</li>
              <li>details of any data we collect about you from a third party (such as publicly available information)</li>
              <li>the right to lodge a complaint with the ICO</li>
              <li>details of the existence of automated decision-making, including profiling (if applicable). </li>
            </ul>
        </nav>
        </div>
        <div className='rghts'>
          <p>
           You also have the right to information that is concise, transparent, intelligible, easily accessible and presented to you in clear and plain language rather than in “legalese”. We would encourage you to get in touch with the contact given above if you have any questions about this policy statement or our procedures with regard to data processing. This will not in any way affect your right (mentioned above) to complain to the ICO. 
          </p>
          <p>
           Finally, we commit to informing you if, at any time, we update our privacy information and always to seek permission if we plan to use your personal data for a new purpose. 
          </p>
        </div>
  </section>

  <section className='terms'>
    <div>
      <h4>The information we collect </h4>
      <p>We process and store details of your:</p>
    </div>
    <div className="info-bo">
      <nav className="benefits">
        <ul>
          <li>Name</li>
          <li>chosen mode of address (Mrs, Ms, etc) </li>
          <li>job title</li>
          <li>date of birth</li>
          <li>Address</li>
          <li>IP address</li>
          <li>email address</li>
          <li>username(s)</li>
        </ul>
      </nav>
    </div>
    <div>
      <p>
      These details will typically be provided when you take out a subscription or sign up to receive a newsletter or future details of our products/services. We only keep them for as long as necessary and you may, at any time, contact us to ask for them to be removed (see “the right to be forgotten” below).
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Why do we need this information? </h4>
      <p>We use the information that we collect and store about you for but not limited to:</p>
    </div>
    <div>
      <nav className="benefits">
        <ul>
          <li>provide our products/services</li>
          <li>manage invoices and accounts </li>
          <li>deliver marketing and events information</li>
          <li>invite participation in polls and surveys.</li>
        </ul>
      </nav>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>The legal basis under which we collect and store data</h4>
      <p>There are six possible legal grounds under the GDPR. These are:</p>
    </div>
    <div>
      <nav className="benefits">
        <ul>
          <li>Consent</li>
          <li>Fulfilment of a contact</li>
          <li>Legitimate interests</li>
          <li>Vital interests</li>
          <li>Public task</li>
          <li>Legal obligation.</li>
        </ul>
      </nav>
    </div>
    <div>
      <p><span className='legal-span'>“consent”</span> to use personal data must be freely given and be provided in an intelligible and easily accessible form, using clear and plain language (not pre-ticked boxes or any other method of default consent). It must be as easy to withdraw consent as it is to give it.</p>
      <p><span className='legal-span'>“Legal obligations”</span> is a lawful basis if an organization needs to process the personal data to comply with a common law or statutory obligation. The ICO makes clear that “you cannot rely on this lawful basis if you have discretion over whether to process the personal data, or if there is another reasonable way to comply”.</p>
      <p><span className='legal-span'>“Vital interests”</span> is unlikely to feature in most organization' routine plans as it covers the need to process the personal data to protect someone’s life. If the person’s vital interests can reasonably be protected in another less intrusive way, this basis will not apply.</p>
      <p><span className='legal-span'>“Public task”</span> is mainly relevant to public authorities or to organizations that exercise official authority or carry out tasks in the public interest. Again, to quote the ICO: “If you could reasonably perform your tasks or exercise your powers in a less intrusive way, this lawful basis does not apply.”</p>
      <p><span className='legal-span'>“Legitimate interests”</span> as the legal basis most likely to be used, if consent does not apply. It is the most flexible, and likely to be most appropriate where you are using people’s data in ways they would reasonably expect and which have a minimal privacy impact, or where there is a compelling justification for the processing.</p>
      <p>
      <span className='legal-span'>Legal basis — legitimate interests</span>
        “Odins Medical has carried out a legitimate interests assessment (LIA) which can be seen on request. In doing so, we have checked that the processing is necessary and that there is no less intrusive way to achieve the same result. We will only use your data in ways that you would reasonably expect, unless we have a very good reason. We will not use your data in ways that you would find intrusive or which could cause you harm and we have considered and introduced safeguards to reduce the impact where possible.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Applying the data protection principles</h4>
      <p>Odins Medical is committed to applying the principles set out in the GDPR. To that end, we will always strive to ensure that:</p>
    </div>
    <div>
      <nav className="benefits">
        <ul>
          <li>personal data is collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes</li>
          <li>our procedures are adequate, relevant and limited to what is necessary in relation to the purposes for which they are put in place</li>
          <li>the data we collect are accurate and, where necessary, kept up to date, every reasonable step will be taken to ensure that data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay</li>
          <li>data are kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which they are processed</li>
        </ul>
      </nav>
      <div>
        <p>data are processed in a manner that ensures their appropriate security, including protection against unauthorized or unlawful processing and against accidental loss, destruction or damage, using appropriate technical measures.</p>
      </div>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Better by design</h4>
    </div>
    <div>
      <p>
        In applying the above principles, Odins Medical recognizes that it has a general obligation to implement technical and organizational measures to show that it has considered and integrated data protection into all data processing activities. We have built safeguards into products and services from the earliest stage of development and privacy-friendly default settings are the norm for all our services. All our employees are trained in the requirements of GDPR and as far as possible we aim to ensure that contracts, website designs, publicity materials and HR policies are all in line with the GDPR requirements.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Access to your data</h4>
    </div>
    <div>
      <p>
        On receipt of a request for access to the data which we hold about you, we will respond without delay and at the latest within one month of receipt. Information will be provided free of charge although a reasonable fee may be applied when a request requires excessive work, particularly if it is repetitive. This fee will reflect the amount of administrative work involved.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>The right to be forgotten</h4>
    </div>
    <div>
      <p>
        Also known as data erasure, the “right to be forgotten” set out in the GDPR entitles you to ask any data controllers (including Odins Medical) to erase your personal data and to cease further dissemination. You can make such a request either verbally or in writing and we will respond as quickly as possible, and at the latest, within one month. We understand that there is a particular emphasis on the right to erasure if the request relates to data collected from children and such requests will always be given the highest priority.
      </p>
      <p>Please note, however, that there are certain circumstances in which the right to erasure may not apply. These include where processing is necessary for one of the following reasons:</p>
      <nav className='benefits'>
        <ul>
          <li>to comply with a legal obligation</li>
          <li>to exercise the right of freedom of expression and information</li>
          <li>for the performance of a task carried out in the public interest or in the exercise of official authority for the establishment, exercise or defense of legal claims.</li>
        </ul>
      </nav>
      <p>
        In addition, any organization is allowed to refuse to comply with a request for erasure if it is manifestly unfounded or excessive, taking into account whether the request is repetitive in nature. We will, however, explain and justify any such refusal.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Right to be informed</h4>
    </div>
    <div>
      <p>
        Within one month of collecting your personal data, we will inform you of the purposes for processing that data, the retention periods and with whom it will be shared. Any information which is provided to you will be concise, transparent, intelligible, easily accessible and presented in clear and plain language.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Right to rectification</h4>
    </div>
    <div>
      <p>
        Either verbally or in writing, you may ask for inaccurate personal data to be rectified, or to be completed if it is partial. We will respond as quickly as possible and certainly within the one-monthtime period allowed under the GDPR. In the unlikely event that there is disagreement over the accuracy of the data, we will do our best to resolve this and you will, of course, have the right to take the matter to the ICO if we cannot reach agreement. If that situation arises, we are prepared to consider restricting processing of the contested data during the time it takes to resolve the issue with the ICO.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Children</h4>
    </div>
    <div>
      <p>
        Under the GDPR, only children aged 13 or over are able provide their own consent. For those under this age, we will seek consent from whoever holds parental responsibility or, if we are using a different legal basis, will inform that person accordingly. We are fully aware that children have the same rights as adults over their personal data and are committed to ensuring full protection for them at all times.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Right to data portability</h4>
    </div>
    <div>
      <p>
        This organizationrecognizes that, under the GDPR, you must be able to move, copy or transfer personal data easily from one IT environment to another in a safe and secure way, without hindrance to usability. The requested information will be provided free of charge in a structured, commonly used and machine-readable form. However, it should be noted that the right to data portability only applies:
      </p>
      <nav className="benefits">
        <ul>
          <li>to personal data an individual has provided to a controller</li>
          <li>•	where the processing is based on the individual’s consent or for the performance of a contract when processing is carried out by automated means.</li>
        </ul>
      </nav>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Right to object</h4>
      <p>You have the right to object to:</p>
    </div>
    <div>
      <nav className="benefits">
        <ul>
          <li>processing based on legitimate interests or the performance of a task in the public interest/exercise of official authority (including profiling)</li>
          <li>direct marketing (including profiling)</li>
          <li>processing for purposes of scientific/historical research and statistics.</li>
        </ul>
      </nav>
      <p>
        We will stop processing personal data for direct marketing purposes as soon as an objection is received.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Automated decision-making and profiling</h4>
    </div>
    <div>
      <p>
        Profiling refers to the automated processing of personal data to evaluate certain things about an individual. Together with making a decision solely by automated means, it is covered by the GDPR and will require the individual’s explicit consent. Odins Medical will only collect the minimum amount of data needed and will retain it only for as long as is necessary. Anyone affected by an automatic decision has the right to ask for it to be reconsidered and we have additional checks in place for profiling/automated decision-making systems to protect vulnerable groups such as children.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Data breaches</h4>
    </div>
    <div>
      <p>
        While Odins Medical will take all appropriate measures to prevent illegal access to your data, we have to prepare for that possibility. Should there be a significant data breach affecting your data and rights, we will notify you (and the ICO) as soon as possible. To minimize any possible danger, we will use encryption and/or pseudonymization where it is appropriate to do so. We will also have backup systems in place in the event that an outside organization attempts to disrupt access to our data.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>International transfers</h4>
    </div>
    <div>
      <p>
        Given that all members of the European Economic Area (EEA) (that is, all EU Member States, plus Norway, Iceland, and Liechtenstein) have tocomply with the Union’s standards on data protection, and particularly with the GDPR, then we can legally transfer data to those countries. However, if we have reason to send data to non-EEA countries, Odins Medical recognize that they must have equivalent standards in place. This is not a matter for individual organizations to assess but must be based on, for example, standard data protection clauses in the form of template transfer clauses adopted by the European Commission or compliance with an approved Code of Conduct approved by the ICO.
        Should such transfers take place, we will make it clear which of these provisions we have adopted to ensure safety. (Among the countries recognized by the commission as having equivalent standards are Switzerland, New Zealand and Canada. USA companies that have certified with the EU-US Privacy Shield program are also considered to be safe destinations).
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Cookies</h4>
    </div>
    <div>
      <p>
        A cookie is a small text file placed on your computer or device by our site when you visit certain parts of it and/or use certain of its features. For example, we may monitor how many times you visit, which pages you go to, traffic data, location data, weblogs and other communication data whether required for billing purposes or otherwise. We may also look at the originating domain name of a user’s internet service provider, IP address, operating system and browser type. This information helps us to build a profile of our users. Where appropriate, this data will be aggregated or statistical, which means that we will not be able to identify you individually.
      </p>
      <p>
        Cookies are also used to remember your settings (language preference, for example) and for authentication (so that you do not have to repeatedly sign in). You can set your browser not to accept cookies and there are a number of websites which explain how to remove cookies from your browser.However, it is possible that some of our website features may not function as a result.
      </p>
    </div>
  </section>

  <section className="terms">
    <div>
      <h4>Third party websites</h4>
    </div>
    <div>
      <p>Please note that there are some links on our website to other sites where you may find useful information. This does not indicate a general endorsement of those sites and, as we have no control over how data is collected, stored, or used by other websites, we would advise you to check their privacy policies before providing any data to them.</p>
    </div>
  </section>

  <section className="terms">
    <div className='LastUpdated'>
      <p>
        Last updated on 05/03/2024 by Somuadina Reuben Odinnuta who can be contacted at: Reuben@odinsmedical.com
      </p>
    </div>
  </section>

    </>
  )
}

export default Policy