import React from 'react'
import Meta from '../../components/Meta'
import Header from "../../components/Header"
import HeaderImage from '../../images/Sign-up.JPG'
import { Link } from 'react-router-dom'

import './accounts.css'






const SignUp = () => {
  return (
    <>
      <Meta title={"O-HUB"} />
      <Header title="O-HUB" image={HeaderImage}>
      </Header>

      <section className="o-hub">
        <div className="container o-hub__wrapper">
            <div className="o-hub__content">
                <h2>Welcome To Odinsmedical O-HUB</h2>
                <p>
                    The agency that takes pride in matching each vacant position with the best possible staff! <span className='TtT'> #Matchingcare. </span>
                </p>
            <div className="bttn__card">
                <button>
                <Link to="https://odinsmedical.agencyportal.co.uk/candidate/register" className='lnk'>Register / Login</Link>
                </button>
            </div>
            </div>
        </div>
      </section>


    </>
  )
}

export default SignUp