import React from 'react'
import { useEffect, useState } from 'react'

const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 200) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

  return (
    <div className='btop'>
        {backToTopButton && (
            <button style={{
                position: "fixed",
                bottom: "50px",
                right: "50px",
                height: "25px",
                width: "25px",
                fontSize: "25px",
                cursor: "pointer",
                background: "red",
                color: "white",
            }}
            onClick={scrollUp}
            className='backtp'>^</button>
        )}
    </div>
  )
}

export default BackToTopButton