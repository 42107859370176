import {Link} from 'react-router-dom'
import './notFound.css'

const NotFound = () => {
  return (
   <section className='notfound'>
    <div className="container notfound__container">
        <h2>Page Not Found</h2>
        <Link to="/" className='btn'>Go Back To Home</Link>
    </div>
   </section>
  )
}

export default NotFound